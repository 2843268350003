
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import {
  GetReportCallsListRequestModel,
  type GetReportsRequest,
  GetReportsResponse,
  ReportCallModel,
  ReportItem,
  ReportItemRenderData,
} from "@/services/types/reports";
import { getStore } from "@/store/getStore";
import { BaseClient } from "@/services/client/BaseClient";
import { getClientInstance } from "@/services/client/clientProvider";
import {
  createReportsRenderList,
  getRatingColor,
} from "@/services/reports/reportsUtils";
import PlayerComponent from "@/components/lib/player/PlayerComponent.vue";
import RatingColorBar from "@/components/ui/RatingColorBar.vue";
import moment from "moment/moment";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket, faClock,
  faLink,
  faPause,
  faPlay, faWeightHanging,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import EIcon from "@/components/ewa/general/Icon.vue";
import CallsDetails from "@/views/calls/details/CallsDetails.vue";
import { BaseEntityModel } from "@/services/types/base";
import apiClient from "@/services/api/apiClient";
import AudioPlayer from "@/components/player2/AudioPlayer.vue";

// TODO: add watchers for updating details & calls list
@Component({
  methods: { getRatingColor },
  components: {
    AudioPlayer,
    CallsDetails,
    EIcon,
    RatingColorBar,
    PlayerComponent,
  },
})
export default class Reports2TableRowDetails extends Vue {
  @Prop() report: ReportItemRenderData;

  iconLink = faLink;
  iconsDirection = {
    incoming: faArrowRightToBracket,
    outgoing: faArrowRightFromBracket,
  };
  iconWeight = faWeightHanging;
  iconDuration = faClock;

  isLoading = false;
  rawData: ReportItem[] = [];
  detailsReportsList: BaseEntityModel[] = [];
  selectedReportId: number = null;

  async mounted() {
    this.isLoading = true;
    try {
      await this.fetchDetailsData(this.report.reportId);
      await this.setSelectedReport(this.renderData[0].reportId);
    } finally {
      this.isLoading = false;
    }
  }

  get getUseEstimationDates() {
    return getStore().getters.getReports2useEstimationDates;
  }

  @Watch('getUseEstimationDates')
  onUseEstimationDatesChange() {
    this.setSelectedReport(this.selectedReportId);
  }

  async fetchDetailsData(id: number) {
    const client: BaseClient = getClientInstance();
    const pageType = getStore().getters.getReports2Page;
    const filterReportId = getStore().getters.getReports2FilterReportId;
    const request: GetReportsRequest = {
      ...getStore().getters.getReports2DateFilter,
      useEstimationDates: getStore().getters.getReports2useEstimationDates,
    };

    let response: GetReportsResponse;

    if (filterReportId !== null) {
      response = await (pageType === "managers"
        ? client.getCriterionReports(id, request)
        : client.getManagerReports(id, request));

      this.rawData = response.items.filter((i) => i.id === filterReportId);
      this.detailsReportsList = response.rating;
    } else {
      response = await (pageType === "managers"
        ? client.getManagerReports(id, request)
        : client.getCriterionReports(id, request));

      this.rawData = response.items;
      this.detailsReportsList = response.rating;
    }
  }

  get renderData(): ReportItemRenderData[] {
    const list = createReportsRenderList(
      this.rawData,
      this.detailsReportsList,
      getStore().getters.getReports2SelectedProjects,
      // response.projects.map((i) => i.id)
    );
    list.sort((a, b) => b.totalAvgRating - a.totalAvgRating);
    return list;
  }

  async setSelectedReport(id: number) {
    this.selectedReportId = id;
    this.callsIsLoading = true;
    try {
      await this.fetchCalls();
    } finally {
      this.callsIsLoading = false;
    }
  }

  //region Call
  reportCalls: ReportCallModel[] = [];
  callsIsLoading = false;
  callDetailsShow = false;
  @Ref() readonly callsDetailsRef!: CallsDetails;

  get filteredReportCalls() {
    return this.reportCalls.filter((i) =>
      getStore().getters.getReports2SelectedProjects.includes(i.project.id),
    );
  }

  formatCallDate(date: string) {
    return moment(date).format("DD.MM.YYYY hh:mm");
  }

  formatCallDuration(duration: number) {
    return moment.utc(duration * 1000).format("mm:ss");
  }

  getLink(call: any) {
    return call.link || call.data?.link || undefined;
  }

  async showCallDetails(id: number) {
    getStore().dispatch(StoreActionTypes.SET_SELECTED_CALL_CLEAR, null);
    await getStore().dispatch(StoreActionTypes.SET_SELECTED_CALL, id);
    // await router
    //   .push({
    //     query: {
    //       callId: id.toString(),
    //     },
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    this.callDetailsShow = true;
  }

  async fetchCalls() {
    const client: BaseClient = getClientInstance();
    const pageType = getStore().getters.getReports2Page;
    const filterReportId = getStore().getters.getReports2FilterReportId;
    let managerId: number, criterionId: number;

    if (filterReportId !== null) {
      managerId =
        pageType == "managers" ? this.selectedReportId : this.report.reportId;
      criterionId =
        pageType == "managers" ? this.report.reportId : this.selectedReportId;
    } else {
      managerId =
        pageType == "managers" ? this.report.reportId : this.selectedReportId;
      criterionId =
        pageType == "managers" ? this.selectedReportId : this.report.reportId;
    }

    const sortModel = getStore().getters.getReportsCallsListSortValue;
    const request: GetReportCallsListRequestModel = {
      filter: {
        managerId,
        criterionId,
        projects: getStore().getters.getReports2Projects.map((p) => p.id),
        from: getStore().getters.getReports2DateFilter.from,
        to: getStore().getters.getReports2DateFilter.to,
        useEstimationDates: this.getUseEstimationDates,
      },
      sort: getStore().getters.getReportsCallsListSortValue,
    };

    const response = await client.getReportCallsList(request);
    this.reportCalls = response.items;
  }

  async fetchCallData(callId: number) {
    // const client: BaseClient = getClientInstance();
    // return client.getCallDetails(callId);
    return apiClient.entitiesApi.getEntity(callId);
  }

  async handleOverlayClick(event: PointerEvent) {
    this.playingCallId = null; // hack for avoid duplicating players

    if (
      this.callsDetailsRef &&
      (event.target as HTMLDivElement).contains(this.callsDetailsRef.$el)
    ) {
      this.callDetailsShow = false;
    }
  }

  //endregion

  //region Player

  playBtn: IconDefinition = faPlay;
  pauseBtn: IconDefinition = faPause;
  playingCallId: number = null;
  playingCallIndex: number = null;
  isPlayerLoading = false;

  getPlayerIcon(id: number, index: number = null): IconDefinition {
    return getStore().getters.isPlayerPlay &&
    this.playingCallId === id &&
    this.playingCallIndex === index
      ? this.pauseBtn
      : this.playBtn;
  }

  getIsCurrentlyPlaying(id: number) {
    return getStore().getters.isPlayerPlay && this.playingCallId === id;
  }

  async playCall(id: number, index: number = null) {
    if (this.isPlayerLoading) return;

    const isPlayerPlay = getStore().getters.isPlayerPlay;

    if (
      this.playingCallId === null ||
      this.playingCallId !== id ||
      this.playingCallIndex !== index
    ) {
      this.playingCallId = id;
      this.playingCallIndex = index;

      try {
        this.isPlayerLoading = true;
        await getStore().dispatch(
          StoreActionTypes.SET_PLAYER_CLEAR_PLAYER,
          null,
        );
        const call = await this.fetchCallData(id);
        if (call.attachments && call.attachments.length) {
          const storageLink = call.attachments[0].storageLink;
          await getStore().dispatch(
            StoreActionTypes.SET_PLAYER_URL,
            `${storageLink}/${call.id}.mp3`,
          );
          await getStore().dispatch(StoreActionTypes.SET_PLAYER_PLAY, true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isPlayerLoading = false;
      }
    } else {
      await getStore().dispatch(
        StoreActionTypes.SET_PLAYER_PLAY,
        !isPlayerPlay,
      );
    }
  }

  //#endregion
}
