
import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { getStore } from "@/store/getStore";
import { Reports2MutationTypes } from "@/store/modules/reports2/mutations/types";
import { BaseEntityModel } from "@/services/types/base";
import { Reports2ActionTypes } from "@/store/modules/reports2/actions/types";
import { ReportsPageType } from "@/services/types/reports";
import {
  dateIntervals,
  getDateFilterFromInterval,
  REPORTS_NONE_DATE_INTERVAL,
} from "@/services/reports/dateIntervals";
import Reports2SavedFiltersPanel from "@/components/reports2/header/Reports2SavedFiltersPanel.vue";
import { getDefaultState } from "@/store/modules/reports2/state";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import { resetSelectedSnap } from "@/services/reports/reportsSnaps";
import { limitString } from "@/services/utils";

@Component({
  components: {
    Reports2SavedFiltersPanel,
    ActionButton,
  },
  watch: {
    selectedProjects: "resetSelectedFilter",
    selectedReports: "resetSelectedFilter",
  },
})
export default class Reports2Filters extends Vue {
  get selectedReports() {
    return getStore().getters.getReports2SelectedReports;
  }

  //#region ReportFilter
  get reportFilterOptions() {
    const options = getStore().getters.getReports2FilterReportOptions;
    if (!options.length) return [];

    const items = options.map((o) => ({
      key: o.id,
      value: o.name,
    }));
    items.unshift({
      key: null,
      value: "Общий отчет",
    });
    return items;
  }

  get reportFilterId() {
    return getStore().getters.getReports2FilterReportId;
  }

  get reportFilterText() {
    return limitString(
      this.reportFilterOptions.find((r) => r.key === this.reportFilterId)
        ?.value,
      30
    );
  }

  async handleFilterReportChange(id: number | null) {
    await getStore().dispatch(
      Reports2ActionTypes.FETCH_REPORTS2_REPORT_DATA,
      id
    );
  }
  //#endregion

  //#region Projects
  get projects() {
    return getStore().getters.getReports2Projects;
  }

  get selectedProjects() {
    const selected = getStore().getters.getReports2SelectedProjects;
    return this.projects.filter((p) => selected.includes(p.id));
  }

  handleProjectsCheckboxChange(items: BaseEntityModel[]) {
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_SELECTED_PROJECTS,
      items.map((i) => i.id)
    );
    getStore().dispatch(Reports2ActionTypes.SET_REPORTS2_RENDER_LIST, null);
  }

  //#endregion

  //#region PageType
  pageTypes = {
    managers: "По менеджерам",
    criterion: "По критериям",
  };

  get selectedPageType() {
    return getStore().getters.getReports2Page;
  }

  handleChangePageType(type: "criterion" | "managers") {
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_PAGE,
      type as ReportsPageType
    );
    getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
  }

  handleChangeUseEstimationDates(value: boolean) {
    getStore().commit(Reports2MutationTypes.SET_REPORTS2_USE_ESTIMATION_DATES, value);
    getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
  }

  get getUseEstimationDates() {
    return getStore().getters.getReports2useEstimationDates;
  }

  get getUseEstimationDatesText() {
    return this.getUseEstimationDates
      ? "По дате оценки"
      : "По дате звонка";
  }

  //#endregion

  //#region Dates
  dateIntervals = dateIntervals;

  get selectedDateIntervalValue() {
    return getStore().getters.getReports2DateInterval;
  }

  get dateFilter() {
    return getStore().getters.getReports2DateFilter;
  }

  handleSelectDateIntervalChange(intervalValue: number) {
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_DATE_INTERVAL,
      intervalValue
    );
    const newFilter = getDateFilterFromInterval(intervalValue);
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_DATE_FILTER,
      newFilter
    );
    getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
  }

  handleDateFilterFromChange(date: string) {
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_DATE_INTERVAL,
      REPORTS_NONE_DATE_INTERVAL
    );
    getStore().commit(Reports2MutationTypes.SET_REPORTS2_DATE_FILTER, {
      from: date,
      to: getStore().getters.getReports2DateFilter.to,
    });
    getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
  }

  handleDateFilterToChange(date: string) {
    getStore().commit(
      Reports2MutationTypes.SET_REPORTS2_DATE_INTERVAL,
      REPORTS_NONE_DATE_INTERVAL
    );
    getStore().commit(Reports2MutationTypes.SET_REPORTS2_DATE_FILTER, {
      from: getStore().getters.getReports2DateFilter.from,
      to: date,
    });
    getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
  }

  //#endregion

  resetDefaultState() {
    const state = getDefaultState();
    getStore().commit(
      StoreMutationTypes.SET_REPORTS2_REPORTS_SNAP_ID,
      state.selectedReportsSnapId
    );
    getStore().commit(
      StoreMutationTypes.SET_REPORTS2_DATE_INTERVAL,
      state.dateInterval
    );
    getStore().commit(
      StoreMutationTypes.SET_REPORTS2_DATE_FILTER,
      state.dateFilter
    );
    getStore().commit(StoreMutationTypes.SET_REPORTS2_PAGE, state.page);
    getStore().commit(StoreMutationTypes.SET_REPORTS2_TARGETS, state.targets);
    getStore().commit(StoreMutationTypes.SET_REPORTS2_TARGETS, state.targets);
    getStore().dispatch(StoreActionTypes.FETCH_REPORTS2_DATA, null);
  }

  resetSelectedFilter() {
    resetSelectedSnap();
  }
}
